import {
    getExistingCheckout,
    isOnlyBrandQuery,
    isEmptyFilter,
    getPriceFilteredProducts,
    isOpen,
} from '../util';
import { dutchieQuery } from '../api/api';
import { sentryCapture } from '../errorHandlers';
import * as storage from '../browserStorage';
import { storageItemName } from '../config';
import { useQuery, useInfiniteQuery, useQueries } from '@tanstack/react-query';

export const useCartData = (retailerId, cartCreated = null) => {
    const checkout = getExistingCheckout();

    return useQuery({
        queryKey: [
            'FetchCartDetails',
            {
                checkoutId: checkout?.checkoutId,
                retailerId: checkout?.retailerId,
            },
        ],
        queryFn: () =>
            dutchieQuery('FetchCartDetails', {
                checkoutId: checkout?.checkoutId,
                retailerId: checkout?.retailerId,
            }).then((res) => {
                if (res.hasOwnProperty('errors') && !res?.data?.checkout) {
                    if (res.errors.some((err) => err.message === 'Not Found')) {
                        const error = new Error(res.errors[0].message);
                        error.level = 'warning';
                        sentryCapture(error, res.errors[0]);
                        storage.removeItem(storageItemName);
                        return null;
                    } else {
                        throw new Error();
                    }
                }

                if (res.hasOwnProperty('errors')) {
                    res.errors.level = 'warning';
                    sentryCapture(res.errors);
                }
                
                return res.data.checkout;
            }),
        enabled: Boolean(
            retailerId &&
                checkout?.retailerId === retailerId &&
                (cartCreated !== null ? !cartCreated.current : true)
        ),
        throwOnError: (err) => {
            err.level = 'fatal';
            sentryCapture(err);
            return true;
        },
        refetchOnMount: false,
    });
};

export const useMenuProducts = (
    retailerId,
    queryArgs,
    {
        enabled,
        setFilterTerms,
        filterTerms,
        paginationLimit,
        selectedFilters,
        menuFilter,
        brandTerms,
        productCacheLimit,
    }
) => {
    return useInfiniteQuery({
        queryKey: [retailerId, queryArgs?.queryName, queryArgs?.variables],
        queryFn: ({ pageParam = 0 }) =>
            dutchieQuery(queryArgs.queryName, {
                ...queryArgs.variables,
                limit: productCacheLimit,
                offset: pageParam * paginationLimit,
            }).then((res) => {
                if (
                    res.hasOwnProperty('errors') &&
                    !res?.data?.menu?.products &&
                    !res?.data?.menu?.brands
                ) {
                    throw new Error('No products or brands found');
                } else {
                    if (res.hasOwnProperty('errors')) {
                        res.errors.level = 'warning';
                        sentryCapture(res.errors);
                    }

                    const { products, brands } = res.data.menu || {};

                    if (isEmptyFilter(selectedFilters, menuFilter)) {
                        setFilterTerms({
                            ...filterTerms,
                            brands: brandTerms,
                        });
                    } else {
                        setFilterTerms({
                            ...filterTerms,
                            brands,
                        });
                    }

                    return {
                        data: products || [],
                        pageParam: pageParam + 1,
                    };
                }
            }),
        initialPageParam: 0,
        getNextPageParam: (lastPage) => {
            if (!lastPage || !lastPage.data) {
                return undefined;
            }
            return lastPage.data.length < paginationLimit
                ? undefined
                : lastPage.pageParam;
        },
        enabled,
        throwOnError: (err) => {
            err.level = 'fatal';
            sentryCapture(err);
            return true;
        },
        staleTime: 0,
        retryDelay: (attempt) =>
            Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    });
};

export const useProductsGridMenu = (
    retailerId,
    queryVars,
    priceFilters,
    pricingType,
    productAtts,
    isRelatedProducts,
    paginationLimit
) => {
    return useQuery({
        queryKey: ['MenuQuery', queryVars, retailerId],
        queryFn: () =>
            dutchieQuery('MenuQuery', queryVars).then((res) => {
                if (
                    res.hasOwnProperty('errors') &&
                    !res?.data?.menu?.products
                ) {
                    throw new Error();
                } else {
                    if (res.hasOwnProperty('errors')) {
                        res.errors.level = 'warning';
                        sentryCapture(res.errors);
                    }

                    return res.data.menu.products;
                }
            }),
        select: (products) =>
            Boolean(priceFilters !== null)
                ? getPriceFilteredProducts(
                      products,
                      priceFilters,
                      pricingType,
                      paginationLimit
                  )
                : products,
        enabled: Boolean(
            queryVars !== null && !productAtts && !isRelatedProducts
        ),
        throwOnError: (err) => {
            err.level = 'fatal';
            sentryCapture(err);
            return true;
        },
        staleTime: Infinity,
    });
};

export const useRelatedProducts = (
    retailerId,
    filter,
    paginationLimit,
    isRelatedProducts,
    getVariables
) => {
    const fetchProducts = async (updatedFilter) => {
        let allProducts = [];
        let currentFilter = updatedFilter;
        while (allProducts.length < paginationLimit) {
            const vars = getVariables(currentFilter);
            try {
                const res = await dutchieQuery('MenuQuery', vars);
                if (res.hasOwnProperty('errors')) {
                    sentryCapture({ ...res.errors, level: 'warning' });
                    if (!res.data?.menu?.products) {
                        throw new Error(
                            'No products available with current filters.'
                        );
                    }
                }
                allProducts = [...allProducts, ...res.data.menu.products];
                if (allProducts.length >= paginationLimit) break;
                currentFilter = {
                    ...currentFilter,
                    strainType: '',
                    brandIds: [],
                };
            } catch (err) {
                sentryCapture({ message: err.message, level: 'warning' });
                break;
            }
        }
        return allProducts.slice(0, paginationLimit);
    };

    return useQuery({
        queryKey: [
            'MenuQuery',
            getVariables(filter),
            'relatedProductsSlider',
            retailerId,
        ],
        queryFn: () => fetchProducts(filter),
        enabled: Boolean(isRelatedProducts),
        staleTime: Infinity,
        throwOnError: false,
    });
};

export const useRetailerData = (retailerId) => {
    return useQuery({
        queryKey: ['RetailerQuery', retailerId],
        queryFn: () =>
            dutchieQuery('RetailerQuery', { retailerId }).then((res) => {
                if (res.hasOwnProperty('errors') && !res?.data?.retailer) {
                    throw new Error();
                }

                if (res.hasOwnProperty('errors')) {
                    res.errors.level = 'warning';
                    sentryCapture(res.errors);
                }

                return res.data.retailer;
            }),
        staleTime: Infinity,
        throwOnError: (err) => {
            err.level = 'fatal';
            sentryCapture(err);
            return true;
        },
        enabled: Boolean(retailerId),
    });
};

export const useSingleProducts = (retailerId, productAtts) => {
    return useQueries({
        queries:
            productAtts?.map((productId) => ({
                queryKey: ['ProductQuery', productId, retailerId],
                queryFn: () =>
                    dutchieQuery('ProductQuery', {
                        retailerId,
                        productId,
                    }).then((res) => {
                        if (
                            res.hasOwnProperty('errors') &&
                            !res?.data?.product
                        ) {
                            throw new Error();
                        } else {
                            if (res.hasOwnProperty('errors')) {
                                res.errors.level = 'warning';
                                sentryCapture(res.errors);
                            }

                            return res.data.product;
                        }
                    }),
                enabled: Boolean(productAtts),
                staleTime: Infinity,
                onThrowError: (err) => {
                    err.level = 'error';
                    sentryCapture(err);
                    return false;
                },
            })) ?? [],
        combine: (results) => {
            if (results.some((result) => result.isFetched)) {
                return {
                    productsFetched: true,
                    productsData: results.map((result) => result.data),
                    productsRefetchFns: results.map((result) => result.refetch),
                };
            } else {
                return {
                    productsFetched: false,
                    productsData: null,
                    productsRefetchFns: results.map((result) => result.refetch),
                };
            }
        },
    });
};

export const useSpecialsList = (retailerId) => {
    return useQuery({
        queryKey: ['GetSpecialsList', retailerId],
        queryFn: () =>
            dutchieQuery('GetSpecialsList', { retailerId }).then((res) => {
                if (res.hasOwnProperty('errors') && !res?.data?.specials) {
                    throw new Error();
                }

                if (res.hasOwnProperty('errors')) {
                    res.errors.level = 'warning';
                    sentryCapture(res.errors);
                }

                return res.data.specials;
            }),
        select: (data) => {
            const filtered = data.filter((special) => {
                if (special.hasOwnProperty('scheduleConfiguration')) {
                    const {
                        days,
                        endDate,
                        endStamp,
                        recurringEndTime,
                        recurringStartTime,
                        setEndDate,
                        startStamp,
                    } = special.scheduleConfiguration || {};

                    const current = new Date();
                    const currentISO = current.toISOString();
                    const currentDay = current.getDay();

                    if (startStamp && startStamp < currentISO) {
                        if (
                            (setEndDate || endDate) &&
                            (endDate < currentISO || endStamp < currentISO)
                        ) {
                            // if the end day/stamp has passed.
                            return false;
                        }

                        if (days && Array.isArray(days)) {
                            if (days.includes(currentDay)) {
                                if (recurringStartTime && recurringEndTime) {
                                    //  `isOpen` calculates whether the current
                                    //  time is within a start/end range, so
                                    //  works for this purpose.
                                    return isOpen({
                                        start: recurringStartTime,
                                        end: recurringEndTime,
                                    });
                                } else {
                                    // no specific hours set, so always run.
                                    return true;
                                }
                            } else {
                                // not a day the special runs.
                                return false;
                            }
                        } else {
                            // there are no days specified, so runs every day
                            // regardless.
                            if (recurringStartTime && recurringEndTime) {
                                // see `isOpen` above.
                                return isOpen({
                                    start: recurringStartTime,
                                    end: recurringEndTime,
                                });
                            } else {
                                // no specific hours set, so always run.
                                return true;
                            }
                        }
                    } else {
                        // if hasn't started.
                        return false;
                    }
                }
                // no scheduleConfiguration property.
                return false;
            });

            return filtered;
        },
        staleTime: Infinity,
        throwOnError: (err) => {
            err.level = 'error';
            sentryCapture(err);
            return false;
        },
        enabled: Boolean(retailerId),
    });
};

export const useSingleProduct = (retailerId, productId, { enabled }) => {
    return useQuery({
        queryKey: [
            'ProductQuery',
            {
                retailerId,
                productId,
            },
        ],
        queryFn: () =>
            dutchieQuery('ProductQuery', {
                retailerId,
                productId,
            }).then((res) => {
                if (res.hasOwnProperty('errors') && !res?.data?.product) {
                    throw new Error();
                } else {
                    if (res.hasOwnProperty('errors')) {
                        res.errors.level = 'warning';
                        sentryCapture(res.errors);
                    }

                    return res.data.product;
                }
            }),
        staleTime: Infinity,
        enabled,
        throwOnError: (err) => {
            err.level = 'fatal';
            sentryCapture(err);
            return true;
        },
    });
};

export const useOrdersByOrderNumber = (
    retailerId,
    orderNumber,
    handleTracking = null,
    analytics = null
) => {
    return useQuery({
        queryKey: [
            'OrdersByOrderNumber',
            {
                retailerId,
                orderNumber,
            },
        ],
        queryFn: () =>
            dutchieQuery('OrdersByOrderNumber', {
                retailerId,
                orderNumber,
            }).then((res) => {
                if (res.hasOwnProperty('errors') && !res?.data?.orders) {
                    throw new Error();
                } else {
                    if (res.hasOwnProperty('errors')) {
                        res.errors.level = 'warning';
                        sentryCapture(res.errors);
                    }

                    const order = res.data.orders[0];

                    if (res.data.orders?.length > 0) {
                        if (handleTracking) {
                            handleTracking(orderNumber, order, analytics);
                        }

                        storage.removeItem(storageItemName);
                        return order;
                    }
                }
            }),
        staleTime: Infinity,
        enabled: Boolean(orderNumber),
        throwOnError: (err) => {
            err.level = 'fatal';
            sentryCapture(err);
            storage.removeItem(storageItemName);
            return true;
        },
    });
};
